<template>
  <div>
    <CRow>
      <CCol sm="12">
        <CCard>
          <CCardHeader>
            <strong>Edit User </strong> <small>#{{ id }}</small>
          </CCardHeader>
          <CCardBody>
            <CRow>
              <CCol sm="12">
                <CInput
                  label="Email"
                  :value.sync="user.email"
                />
              </CCol>

              <CCol sm="12">
                <CInput
                  label="Subscribe Link"
                  id="subscribeLink"
                  :value="subscribeLink"
                  readonly
                >
                  <template #prepend-content>
                    <CRow>
                      <CCol sm="6">
                        <CBadge class="copy" @click="copyCode" data-clipboard-target="#subscribeLink">
                          <CLink>Copy</CLink>
                        </CBadge>
                      </CCol>
                    </CRow>
                  </template>
                  <template #append-content>
                    <CRow>
                      <CCol sm="6">
                        <CBadge @click="">Regenerate</CBadge>
                      </CCol>
                    </CRow>

                  </template>
                </CInput>
              </CCol>

              <CCol sm="12">
                <CInput
                  label="Remark"
                  placeholder="Only admin can see it."
                  :value.sync="user.remark"
                />
              </CCol>

              <CCol sm="12">
                <CInput
                  label="Password"
                  type="password"
                  placeholder="Leave it blank if you don't need to change it."
                  :value.sync="newPassword"
                />
              </CCol>

              <CCol sm="12">
                <CCardText>Is Admin</CCardText>
                <CSwitch
                  class="mr-1"
                  color="warning"
                  :checked.sync="user.is_admin"
                />
              </CCol>

              <CCol sm="12">
                <CCardText>Is Enable</CCardText>
                <CSwitch
                  class="mr-1"
                  color="danger"
                  :checked.sync="user.enable"
                />
              </CCol>

              <CCol sm="12">
                <CInput
                  label="Account Credit"
                  :value.sync="user.money"
                />
              </CCol>

              <CCol sm="12">
                <CSelect
                  label="Account Type"
                  :options="accountTypeOptions"
                  :value.sync='user.is_multi_user'
                />
              </CCol>

            </CRow>
          </CCardBody>
        </CCard>

        <CCard>
          <CCardBody>
            <CRow>
              <CCol sm="12">
                <CDataTable
                  hover
                  small
                  fixed
                  sorter
                  :items-per-page="5"
                  :fields="shopListFields"
                  :items="shopListItem"
                  :noItemsView="{ noItems: 'No Item~' }"
                  pagination
                >
                  <template #action="{item}">
                    <td class="py-2">
                      <CButtonGroup>
                        <CButton
                          color="primary"
                          square
                          @click="buyMerchandiseForUser(item.id)"
                        >
                          Buy
                        </CButton>
                      </CButtonGroup>
                    </td>
                  </template>
                </CDataTable>
              </CCol>
            </CRow>
          </CCardBody>
        </CCard>

        <CCard>
          <CCardBody>
            <CRow>
              <CCol sm="12">
                <CInput
                  label="Port"
                  :value.sync='user.port'
                />
              </CCol>
              <CCol sm="12">
                <CInput
                  label="Connect Password"
                  :value.sync='user.passwd'
                />
              </CCol>
              <CCol sm="12">
                <CInput
                  label="Encryption Method"
                  :value.sync='user.method'
                />
              </CCol>
              <CCol sm="12">
                <CInput
                  label="Protocol"
                  :value.sync='user.protocol'
                />
              </CCol>
              <CCol sm="12">
                <CInput
                  label="Protocol Param"
                  :value.sync='user.protocol_param'
                />
              </CCol>
              <CCol sm="12">
                <CInput
                  label="Obfs"
                  :value.sync='user.obfs'
                />
              </CCol>
              <CCol sm="12">
                <CInput
                  label="Obfs Param"
                  :value.sync='user.obfs_param'
                />
              </CCol>
            </CRow>
          </CCardBody>
        </CCard>

        <CCard>
          <CCardBody>
            <CRow>
              <CCol sm="12">
                <CInput
                  label="Bandwidth(GB)"
                  :value.sync='user.enableTraffic'
                />
              </CCol>
              <CCol sm="12">
                <CInput
                  label="Used Bandwidth"
                  disabled
                  :value='user.usedTraffic'
                />
              </CCol>
              <CCol sm="12">
                <CInput
                  label="Auto Reset Day"
                  :value.sync='user.auto_reset_day'
                />
              </CCol>
              <CCol sm="12">
                <CInput
                  label="Reset Bandwidth"
                  :value.sync='user.auto_reset_bandwidth'
                />
              </CCol>
              <CCol sm="12">
                <CInput
                  label="Expire Date"
                  :value.sync='user.expire_in'
                />
              </CCol>
              <CCol sm="12">
                <CInput
                  label="Speed Limit"
                  :value.sync='user.node_speedlimit'
                />
              </CCol>
              <CCol sm="12">
                <CInput
                  label="Client Limit"
                  :value.sync='user.node_connector'
                />
              </CCol>
            </CRow>
          </CCardBody>
        </CCard>
        <CCard>
          <CCardBody>
            <CRow>
              <CCol sm="12">
                <CTextarea
                  label="IP Blacklist"
                  :value='user.forbidden_ip'
                />
              </CCol>
              <CCol sm="12">
                <CTextarea
                  label="Port Blacklist"
                  :value='user.forbidden_port'
                />
              </CCol>
            </CRow>
          </CCardBody>
        </CCard>
        <CCard>
          <CCardBody>
            <CRow>
              <CCol sm="12">
                <CButton
                  color="primary"
                  block
                  square
                  @click="editUser"
                >
                  Submit
                </CButton>
              </CCol>
            </CRow>
          </CCardBody>
        </CCard>
      </CCol>
    </CRow>
    <CModal
      title="Edit"
      color="primary"
      :show.sync="editMsgModal"
    >
      {{ editMsg }}
      <template #footer>
        <CButton
          color="primary"
          square
          @click="editMsgModal=false"
        >
          Got it
        </CButton>
      </template>
    </CModal>
  </div>
</template>

<script>
import ClipboardJS from 'clipboard';

export default {
  name: "Edit",
  data() {
    const shopListFields = [
      {key: 'name', label: 'Plan Name'},
      {key: 'price', label: 'Inner Price'},
      {
        key: 'action',
        label: 'Action',
        sorter: false,
        filter: false,
        _style: 'width: 10%'
      },
    ]
    return {
      id: this.$route.params.id,
      user: [],
      subscribeLink: null,
      newPassword:null,
      editMsgModal: false,
      editMsg: null,
      shopListFields,
      shopListItem:[],
      accountTypeOptions: [
        {value: 1, label: 'Default User'},
        {value: 2, label: 'Single Port User For OBFS'},
        {value: 2, label: 'Single Port User For Protocol'},
      ]
    };
  },
  mounted: function() {
    this.onLoadPage();
  },
  methods: {
    onLoadPage: function() {
      this.$axios.get("admin/user/view/" + this.id, {})
        .then((response) => {
          if (response.data.code === 200) {
            this.user = response.data.user;
            if (this.user.is_admin === 1) {
              this.user.is_admin = true;
            } else {
              this.user.is_admin = false;
            }
            if (this.user.enable === 1) {
              this.user.enable = true;
            } else {
              this.user.enable = false;
            }
            this.user.enableTraffic = response.data.userEnableTraffic;
            this.user.usedTraffic = response.data.userUsedTraffic;
            this.subscribeLink = response.data.userSubLink;
          }
        });

      this.$axios.get("admin/shop/list", {})
        .then((response) => {
          if (response.data.code === 200) {
            this.shopListItem = response.data.shopList;
          }
        });
    },
    copyCode() {
      let clipboard = new ClipboardJS(".copy");
      clipboard.on("success", e => {
        console.log('copy success')
      });
    },
    buyMerchandiseForUser: function(merchandiseId) {
      this.editMsgModal = true;
      this.$axios.post("admin/shop/buy", {
        user_id: this.id,
        merchandise_id: merchandiseId
      })
        .then((response) => {
          this.editMsg = response.data.msg;
          this.onLoadPage();
        });
    },
    editUser: function() {
      this.editMsgModal = true;
      this.$axios.put("admin/user/edit/" + this.id, {
        email: this.user.email,
        remark: this.user.remark,
        password: this.newPassword,
        is_admin: this.user.is_admin,
        enable: this.user.enable,
        money: this.user.money,
        is_multi_user: this.user.is_multi_user,
        port: this.user.port,
        passwd: this.user.passwd,
        method: this.user.method,
        protocol: this.user.protocol,
        protocol_param: this.user.protocol_param,
        obfs: this.user.obfs,
        obfs_param: this.user.obfs_param,
        enableTraffic: this.user.enableTraffic,
        auto_reset_day: this.user.auto_reset_day,
        auto_reset_bandwidth: this.user.auto_reset_bandwidth,
        expire_in: this.user.expire_in,
        node_speedlimit: this.user.node_speedlimit,
        node_connector: this.user.node_connector,
        forbidden_ip: this.user.forbidden_ip,
        forbidden_port: this.user.forbidden_port,
      })
        .then((response) => {
          if (response.data.code === 200) {
            this.editMsg = response.data.msg;
            this.onLoadPage();
          }
        });
    }
  }
};
</script>
